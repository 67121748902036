import moment from 'moment';

let filters = {

    date(value: any, _format: string = "YYYY MMMM DD", _showTime: boolean = false) {
        if (value) {
            let format;
            format = _format;
            if(_showTime)
                format += 'HH:mm:ss';

            return moment(value).format(format);
        }
    },

    timespan(value: string) {
        if(value)
            return value.substring(0, 5);
    },

    currency(value: any, decimals: number) {
        if(!decimals && decimals != 0)
            decimals = 2;
        if (value || value == 0) {
            let val = (value/1).toFixed(decimals).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    },

    underscore(value: string){
        let ris = value;
        ris = ris.replaceAll(", ", "_");
        ris = ris.replaceAll(" ", "_");
        ris = ris.replaceAll("-", "_");
        ris = ris.replaceAll(",", "_");
        ris = ris.replaceAll("'", "_");
        ris = ris.replaceAll(".", "");
        return ris;
    }
    
}

export default filters;

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $filters: typeof filters;
    }
}
  