import * as OM from '@/Model';
import * as Enum from '@/const';
import moment from 'moment';
import store from './store';
import filters from '@/filters';

export function JsonClone<T>(item: T){
    return <T>JSON.parse(JSON.stringify(item));
}

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized){}
        localized = new OM.LocalizedValue();

    if(!localized.values)
        localized.values = {};
        
    var enabledLanguages = store.state.consts.companyCodes;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
}

export function italyFixDateAndTime(value: any, time: string) : string {
    const date = new Date(value)

    var dateHour = parseInt(value.substring(11, 13));

    if(dateHour == 23)
        date.setHours(date.getHours() - 1);
    else if(dateHour == 0)
        date.setHours(date.getHours() - 2);
    else if(dateHour == 21)
        date.setHours(date.getHours() + 1);

    const hour = parseInt(time.substring(0, 2));
    const minutes = parseInt(time.substring(3, 5));
    
    date.setHours(date.getHours() + hour);
    date.setMinutes(date.getMinutes() + minutes);

    return date.toISOString();
}